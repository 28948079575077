<!-- 解决方案 -->
<template>
    <div class="solution">
        <header class="header">
            <div class="header-info">
                <h2>智慧园区解决方案</h2>
                <p>
                    运用人工智能、大数据、移动互联网等新一代信息技术，提供多尺度的城市态势感知和多模事件精准识别处理服务。为城市管理增智赋能，提升城市管理水平，实现城市管理“像绣花一样精细”。
                </p>
                <div class="info-btn">
                  <span><a href="#applyContact">预约试用</a></span>
                  <span @click="qrCode">联系我们</span>
                </div>
            </div>
            <div class="header-img">
                <img v-lazy="require('../../assets/solutions/banner-pc.png')" alt="" />
            </div>
        </header>
        <!-- 功能跳转开始 -->
        <div class="feature-list">
            <ul>
                <li v-for="(item, index) in featureList" :key="index" @click="featureListClick(index)">
                    <a :href="item.id" :class="item.isActive ? 'isActive' : ''">{{ item.name }}</a>
                </li>
            </ul>
        </div>
        <!-- 功能跳转结束 -->
        <!-- 块内容开始 -->
        <div class="content">
            <!-- 行业痛点 -->
            <section class="list1">
                <a name="list1" style="position: relative; top: -4.5rem"></a>
                <h2>行业痛点</h2>
                <div class="container">
                    <div class="list" v-for="(item, index) in list1" :key="index">
                        <div class="circle">{{ item.title }}</div>
                        <div class="details">
                            <p v-for="(item1, index1) in item.details" :key="index1">{{ item1 }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 方案架构 -->
            <section class="list2">
                <a name="list2" style="position: relative; top: -4.5rem"></a>
                <h2>方案架构</h2>
                <div class="container">
                    <p>
                        智慧园区解决方案，以采集多元化、流程极简化、运行智能化为目标，实现城市事件智能识别，充分利用城市视频、无人机、网格员拍照等方式，发挥百度AI
                        能力，实现城市精细化、常态化管理；结合百度接诉即办、知识图谱能力，实现案事件智能分发派单，提升效率；构建城市管理联动指挥体系，实现部门处置线上智能、线下协同。
                    </p>
                    <div class="list"></div>
                </div>
            </section>
            <!-- 方案优势 -->
            <section class="list3">
                <a name="list3" style="position: relative; top: -4.5rem"></a>
                <h2>方案优势</h2>
                <div class="container">
                    <div class="list" v-for="(item, index) in list3" :key="index">
                        <img v-lazy="item.img" alt="" />
                        <div class="info">
                            <h4>{{ item.title }}</h4>
                            <span></span>
                            <p>{{ item.details }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 经典案例 -->
            <section class="list4">
                <a name="list4" style="position: relative; top: -4.5rem"></a>
                <h2>经典案例</h2>
                <div class="container">
					<router-link v-for="(item, index) in list4" :key="index" :to="item.path">
						<div class="list">
							<div class="title">
								<img v-lazy="require('../../assets/solutions/Vector.png')" alt="" />
								<h4>{{ item.title }}</h4>
							</div>
							<p>{{ item.details }}</p>
						</div>
					</router-link>
                </div>
            </section>
            <!-- 更多解决方案 -->
            <section class="list5">
                <a name="list5" style="position: relative; top: -4.5rem"></a>
                <h2>更多解决方案</h2>
                <div class="container">
					<template>
						<router-link v-for="(item, index) in list5" :key="index" :to="{path: item.path}">
							<div class="list">
								<h4>{{ item.title }}</h4>
								<p>{{ item.details }}</p>
								<span>了解详情</span>
							</div>
						</router-link>
					</template>
                </div>
            </section>
        </div>
        <!-- 块内容结束 -->
        <!-- 二维码 -->
        <contactUs ref="contactUs"></contactUs>
        <!-- 移动端二维码 -->
				<apply ref="apply"></apply>
    </div>
</template>

<script>
import apply from "@/components/apply/apply";
import contactUs from "@/components/contactUs/contactUs";
import { myMixins } from "@/utils/mixin/mixin.js";
export default {
    mixins: [myMixins],
    components: { apply, contactUs },
    data() {
        return {
            id: 0, //标识 区分哪一个解决方案，来显示动态显示更多解决方案
            featureList: [
                { name: "行业痛点", isActive: true, id: "#list1" },
                { name: "方案架构", isActive: false, id: "#list2" },
                { name: "方案优势", isActive: false, id: "#list3" },
                { name: "经典案例", isActive: false, id: "#list4" },
                { name: "更多解决方案", isActive: false, id: "#list5" },
            ], //功能列表跳转
            list1: [
                {
                    title: "运营转化难",
                    details: ["人口红利消失", "用户更加理性", "软硬件无法联动", "服务需要定制化"],
                },
                {
                    title: "数据获取难",
                    details: ["数据存在多个系统", "无法及时辅助决策", "产营分离形成壁垒", "不具备未来拓展性"],
                },
                {
                    title: "管理方式乱",
                    details: ["缺乏定位和计划", "盲目招商", "无法持续发展", "专业能力不足"],
                },
                {
                    title: "同质化严重",
                    details: ["全国园区存量大", "被迫陷入价格战", "难以匹配市场需求", "无法形成核心竞争力"],
                },
            ], //行业痛点
            list3: [
                {
                    title: "管理能力提升",
                    img: require("../../assets/solutions/icon1.png"),
                    details:
                        "线上化招商租赁过程支持，实时在线招商信息共享，强化招商管控。线上化招商租赁过程支持，实时在线招商信息共享，强化招商管控。",
                },
                {
                    title: "运营效率提升",
                    img: require("../../assets/solutions/icon2.png"),
                    details:
                        "人员智能化调度，提升人员工作效率，同时减少人力损耗。设施智能化联动，降低楼宇能耗，设施智能化维护，降低物料损耗，实现运营成本的降低。",
                },
                {
                    title: "实现数据交互可视化",
                    img: require("../../assets/solutions/icon3.png"),
                    details:
                        "覆盖数据抽取、转换、加载、建模、分析、报表、治理等环节。实现数据实时图形可视化、场景化以及实时交互。",
                },
                {
                    title: "多元服务助力产品创新",
                    img: require("../../assets/solutions/icon4.png"),
                    details:
                        "以小程序作为智能家居服务入口，无需安装，用完即走，为用户提供方便快捷的操控体验；结合腾讯医疗健康、保险等增值服务，助力产品服务创新。",
                },
            ], //方案优势
            list4: [
                {
                    title: "上海财经大学千村调研2.0系统",
					path: "/customerCase/caseDetails/0",
                    details:
                        "“千村调查”项目是上海财经大学“211工程”三期创新人才培养项目，已成功实施十三期。“走千村，访万户，读中国”。“千村调查”项目是以 ...",
                },
                {
                    title: "保利物业百优行动品检项目",
					path: "/customerCase/caseDetails/1",
                    details:
                        "“百优行动”是保利物业提升服务品质的专项行动，以物业管理基础服务触点动作为契机，采用场景式宣传，标准化服务为切入点，来加强客户感知…",
                },
                {
                    title: "西塘全域化公共服务项目",
					path: "/customerCase/caseDetails/2",
                    details:
                        "2020年9月26日，保利物业正式进驻西塘全域。本次服务升级，旨在将“政府主导、企业协同、服务对象积极参与”的三位一体全域化服务，打造出…",
                },
            ], //经典案例
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        // this.distinguish();
    },
    methods: {
        // 功能列表跳转
        featureListClick(e) {
            this.featureList.map((item, index) => {
                item.isActive = e === index ? true : false;
            });
        },
		toSolutionPage(_path){
			
		}
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    @import "solution_PC.scss";
}
@media screen and (max-width: 750px) {
    @import "solution_mobile.scss";
}
</style>
